import React from 'react';
import PropTypes from 'prop-types';

const Preloader = ({ loading }) => {
  if (!loading) return null;
  return (
    <div id="preloader" className="preloader preloader-dark">
      <div className="loader-status">
        <div className="circle-side" />
      </div>
    </div>
  );
};

Preloader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Preloader;
