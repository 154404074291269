import React from 'react';

const Header = () => (
  <header className="site-header header-mobile-light header-content-light header-content-mobile-dark">
    <div className="header-brand">
      <a className="logo" href="#">
        <img className="logo-light" src="images/logo.png" alt="" />
        <img className="logo-dark" src="images/logo-dark.png" alt="" />
      </a>
      <button type="button" id="navigation-toggle" className="nav-toggle">
        <span />
        <span />
        <span />
      </button>
    </div>
    <div className="header-collapse">
      <div className="header-collapse-inner">
        <nav className="site-nav">
          <ul id="navigation">
            <li data-menuanchor="home">
              <a href="#home">Home</a>
            </li>
            <li data-menuanchor="our-mission">
              <a href="#our-mission">Our mission</a>
            </li>
            <li data-menuanchor="what-we-do">
              <a href="#what-we-do">What we do</a>
            </li>
            <li data-menuanchor="our-solutions">
              <a href="#our-solutions">Our solutions</a>
            </li>
            <li data-menuanchor="subscribe">
              <a href="#subscribe">Subscribe</a>
            </li>
            <li data-menuanchor="our-work">
              <a href="#our-work">Our work</a>
            </li>
            <li data-menuanchor="contact">
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>

);

export default Header;
