import React from 'react';

const BackToTop = () => (
  <a href="#top" className="backtotop">
    <span>Back To Top</span>
    <i className="fas fa-angle-up" />
  </a>
);

export default BackToTop;
