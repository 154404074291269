import React from 'react';

const Footer = () => (
  <footer className="site-footer footer-content-light footer-mobile-content-light">
    <div className="overlay">
      <div className="overlay-inner bg-white" />
    </div>
    <div className="container socials-container">
      <nav className="socials-icons">
        <ul className="mx-auto">
          <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
          <li><a href="#"><i className="fab fa-twitter" /></a></li>
          <li><a href="#"><i className="fab fa-instagram" /></a></li>
          <li><a href="#"><i className="fab fa-pinterest" /></a></li>
        </ul>
      </nav>
    </div>
    <div className="container copyright-container">
      <p>
        ©
        {new Date().getFullYear()}
        {' '}
        Nishikawa - All Rights Reserved
      </p>
    </div>
  </footer>
);

export default Footer;
