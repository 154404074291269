import React from 'react';

const Content = () => (
  <div className="ln-fullpage" data-navigation="true">

    <section className="ln-section d-lg-flex fullscreen-lg" data-anchor="home" data-tooltip="Home">
      <div className="container align-self-lg-center">
        <div className="row">
          <div className="col-12 col-lg-9 col-xl-6">

            <div className="countdown text-white mb-5 animated" data-animation="fadeInUp" data-countdown="2020/10/13 12:00:00" />
            <h2 className="text-white mb-5 animated" data-animation="fadeInUp" data-animation-delay="150">Our website is under construction. We`ll be here soon with our new awesome site.</h2>
            <a href="#our-mission" className="btn btn-primary scrollto animated" data-animation="fadeInUp" data-animation-delay="300">Learn more</a>

          </div>
        </div>
      </div>
    </section>

    <section className="ln-section d-xl-flex" data-anchor="our-mission" data-tooltip="Our mission">
      <div className="container align-self-xl-center">

        <div className="row">
          <div className="col-12 col-lg-8 col-xl-7">
            <h2 className="text-white mb-4 animated" data-animation="fadeInUp" data-animation-delay="150">Our Mission</h2>
            <p className="text-white animated" data-animation="fadeInUp" data-animation-delay="150">Mauris ut sem elementum, tristique velit sed, volutpat mauris. Ut interdum nibh sit amet imperdiet condimentum. Nulla sed tellus placerat, vehicula risus ac, sollicitudin quam. Quisque pharetra porta libero, a sagittis nisl tristique non. Etiam sapien purus, cursus eu pulvinar et, tempor sit amet ipsum. Sed a nisl sit amet lorem dictum hendrerit.</p>
          </div>
        </div>

      </div>
    </section>

    <section className="ln-section d-xl-flex" data-anchor="what-we-do" data-tooltip="What we do">
      <div className="container align-self-xl-center">

        <div className="row">
          <div className="col-12 col-xl-5 mb-5 mb-xl-0">
            <h2 className="text-white animated mb-4" data-animation="fadeInUp">What we Do</h2>
            <p className="text-white animated" data-animation="fadeInUp" data-animation-delay="150"><span>Fusce massa dolor, mattis sed ultrices ut, placerat ut leo. Donec sed fringilla lectus, non vulputate orci. Integer id libero euismod, interdum ligula vel, porttitor magna. Sed euismod maximus finibus. Pellentesque tempus ultricies nisi at cursus. Nulla at nisi tellus. Suspendisse potenti.</span></p>
          </div>
          <div className="col-12 col-xl-6 offset-xl-1">
            <div className="row">

              <div className="col-md-6 col-sm-6">
                <div className="feature-block mb-5 animated" data-animation="fadeInUp">
                  <div className="feature-icon mb-4 text-white">
                    <div>
                        <i className="ti-timer" />
                      </div>
                  </div>
                  <h3 className="h4 text-white">Launch Quickly</h3>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <div className="feature-block mb-5 animated" data-animation="fadeInUp" data-animation-delay="150">
                  <div className="feature-icon mb-4 text-white">
                    <div>
                        <i className="ti-brush-alt" />
                      </div>
                  </div>
                  <h3 className="h4 text-white">Stylish Design</h3>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <div className="feature-block mb-5 animated" data-animation="fadeInUp" data-animation-delay="300">
                  <div className="feature-icon mb-4 text-white">
                    <div>
                        <i className="ti-book" />
                      </div>
                  </div>
                  <h3 className="h4 text-white">Well Documented</h3>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <div className="feature-block mb-5 animated" data-animation="fadeInUp" data-animation-delay="450">
                  <div className="feature-icon mb-4 text-white">
                    <div>
                        <i className="ti-layers" />
                      </div>
                  </div>
                  <h3 className="h4 text-white">Multiple Styles</h3>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <div className="feature-block mb-5 mb-sm-0 animated" data-animation="fadeInUp" data-animation-delay="600">
                  <div className="feature-icon mb-4 text-white">
                    <div>
                        <i className="ti-settings" />
                      </div>
                  </div>
                  <h3 className="h4 text-white">Fully Customizable</h3>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <div className="feature-block animated" data-animation="fadeInUp" data-animation-delay="750">
                  <div className="feature-icon mb-4 text-white">
                    <div>
                        <i className="ti-headphone-alt" />
                      </div>
                  </div>
                  <h3 className="h4 text-white">Friendly Support</h3>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </section>

    <section className="ln-section d-xl-flex" data-anchor="our-solutions" data-tooltip="Our solutions">
      <div className="container align-self-xl-center">
        <div className="row mb-5">
          <div className="col-12 col-lg-8 col-xl-7">
            <h2 className="text-white mb-4 animated" data-animation="fadeInUp">A complete marketing solution</h2>
            <p className="text-white animated" data-animation="fadeInUp" data-animation-delay="150">Mauris ut sem elementum, tristique velit sed, volutpat mauris. Ut interdum nibh sit amet imperdiet condimentum. Nulla sed tellus placerat, vehicula risus ac, sollicitudin quam. Quisque pharetra porta libero, a sagittis nisl tristique non.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 mb-5 animated" data-animation="fadeInUp" data-animation-delay="150">
            <h4 className="text-white">We are</h4>
            <p className="text-white">Quisque ultrices non velit sit amet consectetur. Cras turpis dolor, facilisis a nibh non, ullamcorper facilisis mauris. Nulla rutrum arcu sed ligula malesuada, quis condimentum eros sollicitudin. Morbi eget lorem turpis. Maecenas id metus sapien. Aliquam et est turpis. Sed sit amet dui vel sapien sagittis pellentesque nec id sem.</p>
          </div>
          <div className="col-12 col-lg-6 mb-5 animated" data-animation="fadeInUp" data-animation-delay="300">
            <h4 className="text-white">We practice</h4>
            <p className="text-white">Quisque ultrices non velit sit amet consectetur. Cras turpis dolor, facilisis a nibh non, ullamcorper facilisis mauris. Nulla rutrum arcu sed ligula malesuada, quis condimentum eros sollicitudin. Morbi eget lorem turpis. Maecenas id metus sapien. Aliquam et est turpis. Sed sit amet dui vel sapien sagittis pellentesque nec id sem.</p>
          </div>
          <div className="col-12 col-lg-6 mb-5 mb-lg-0 animated" data-animation="fadeInUp" data-animation-delay="450">
            <h4 className="text-white">We deliver</h4>
            <p className="text-white">Quisque ultrices non velit sit amet consectetur. Cras turpis dolor, facilisis a nibh non, ullamcorper facilisis mauris. Nulla rutrum arcu sed ligula malesuada, quis condimentum eros sollicitudin. Morbi eget lorem turpis. Maecenas id metus sapien. Aliquam et est turpis. Sed sit amet dui vel sapien sagittis pellentesque nec id sem.</p>
          </div>
          <div className="col-12 col-lg-6 animated" data-animation="fadeInUp" data-animation-delay="600">
            <h4 className="text-white">We create and innovate</h4>
            <p className="text-white">Quisque ultrices non velit sit amet consectetur. Cras turpis dolor, facilisis a nibh non, ullamcorper facilisis mauris. Nulla rutrum arcu sed ligula malesuada, quis condimentum eros sollicitudin. Morbi eget lorem turpis. Maecenas id metus sapien. Aliquam et est turpis. Sed sit amet dui vel sapien sagittis pellentesque nec id sem.</p>
          </div>
        </div>
      </div>
    </section>

    <section className="ln-section d-xl-flex" data-anchor="subscribe" data-tooltip="Subscribe">
      <div className="container align-self-xl-center">
        <div className="row">
          <div className="col-12 col-lg-8 col-xl-7">
            <h2 className="text-white mb-4 animated" data-animation="fadeInUp">Get our latest content in your inbox</h2>
            <p className="text-white mb-5 animated" data-animation="fadeInUp" data-animation-delay="150">Pellentesque consequat at justo quis vulputate. Maecenas elementum metus eu maximus auctor. Morbi ultrices quam rhoncus purus bibendum ornare.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-9 col-xl-6">
            <div className="subscribe-form animated" data-animation="fadeInUp" data-animation-delay="300">
              <form className="text-white mb-0" id="sf" name="sf" action="include/subscribe.php" method="post" autoComplete="off">
                <div className="form-row">

                  <div className="form-process" />

                  <div className="col-12 col-md-8">
                    <div className="form-group text-white">
                        <input type="email" id="sf-email" name="sf-email" placeholder="Enter Your Email Address" className="form-control fc-bordered fc-light required" />
                      </div>
                  </div>

                  <div className="col-12 d-none">
                    <input type="text" id="sf-botcheck" name="sf-botcheck" value="" className="form-control" />
                  </div>

                  <div className="col-12 col-md-4">
                    <button className="btn btn-white btn-block" type="submit" id="sf-submit" name="sf-submit">Notify Me</button>
                  </div>

                </div>
              </form>
              <div className="subscribe-form-result pt-2 text-white" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="ln-section d-xl-flex" data-anchor="our-work" data-tooltip="Our work">
      <div className="container align-self-xl-center">
        <div className="row mb-5">
          <div className="col-12 col-lg-8 col-xl-7">
            <h2 className="text-white mb-4 animated" data-animation="fadeInUp">Our Work</h2>
            <p className="text-white animated" data-animation="fadeInUp" data-animation-delay="150">Donec sollicitudin libero vitae massa placerat tempor. Duis rutrum nisl eu ipsum varius, nec feugiat tortor vulputate. Integer pellentesque leo id metus dictum, a tristique tellus faucibus.</p>
          </div>
        </div>
        <div className="slider dots-light animated" data-animation="fadeInUp" data-animation-delay="300" data-slick='{"dots": true}'>

          <div>
            <div className="portfolio-item mb-5">
              <div className="row">
                <div className="col-12 col-lg-7">
                  <div className="item-media">
                    <a href="demo/images/portfolio/project-6.jpg" className="mfp-image">
                        <div className="media-container">
                    <div className="bg-image-holder bg-cover">
                        <img src="demo/images/portfolio/project-6-min.jpg" alt="" />
                      </div>
                  </div>
                      </a>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div className="item-details">
                    <div className="divider divider-alt bg-white mt-0 mb-5 d-none d-lg-block" />
                    <h4 className="h3 item-title text-white">Mountains</h4>
                    <p className="item-cat text-white">Web Design</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="portfolio-item mb-5">
              <div className="row flex-lg-row-reverse">
                <div className="col-12 col-lg-7">
                  <div className="item-media">
                    <a href="demo/images/portfolio/project-1.jpg" className="mfp-image">
                        <div className="media-container">
                    <div className="bg-image-holder bg-cover">
                        <img src="demo/images/portfolio/project-1-min.jpg" alt="" />
                      </div>
                  </div>
                      </a>
                  </div>
                </div>
                <div className="col-12 col-lg-5 d-lg-flex justify-content-lg-end text-lg-right">
                  <div className="item-details">
                    <div className="divider divider-alt bg-white mt-0 mb-5 ml-auto mr-0 d-none d-lg-block" />
                    <h4 className="h3 item-title text-white">Love</h4>
                    <p className="item-cat text-white">Web Design</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="portfolio-item mb-5">
              <div className="row">
                <div className="col-12 col-lg-7">
                  <div className="item-media">
                    <a href="demo/images/portfolio/project-2.jpg" className="mfp-image">
                        <div className="media-container">
                    <div className="bg-image-holder bg-cover">
                        <img src="demo/images/portfolio/project-2-min.jpg" alt="" />
                      </div>
                  </div>
                      </a>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div className="item-details">
                    <div className="divider divider-alt bg-white mt-0 mb-5 d-none d-lg-block" />
                    <h4 className="h3 item-title text-white">Beautiful Girl</h4>
                    <p className="item-cat text-white">Brand</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="portfolio-item mb-5">
              <div className="row flex-lg-row-reverse">
                <div className="col-12 col-lg-7">
                  <div className="item-media">
                    <a href="demo/images/portfolio/project-3.jpg" className="mfp-image">
                        <div className="media-container">
                    <div className="bg-image-holder bg-cover">
                        <img src="demo/images/portfolio/project-3-min.jpg" alt="" />
                      </div>
                  </div>
                      </a>
                  </div>
                </div>
                <div className="col-12 col-lg-5 d-lg-flex justify-content-lg-end text-lg-right">
                  <div className="item-details">
                    <div className="divider divider-alt bg-white mt-0 mb-5 ml-auto mr-0 d-none d-lg-block" />
                    <h4 className="h3 item-title text-white">Future City</h4>
                    <p className="item-cat text-white">Web Design</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="portfolio-item">
              <div className="row">
                <div className="col-12 col-lg-7">
                  <div className="item-media">
                    <a href="demo/images/portfolio/project-4.jpg" className="mfp-image">
                        <div className="media-container">
                    <div className="bg-image-holder bg-cover">
                        <img src="demo/images/portfolio/project-4-min.jpg" alt="" />
                      </div>
                  </div>
                      </a>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div className="item-details">
                    <div className="divider divider-alt bg-white mt-0 mb-5 d-none d-lg-block" />
                    <h4 className="h3 item-title text-white">Golden Gate Bridge</h4>
                    <p className="item-cat text-white">Design</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section className="ln-section d-xl-flex" data-anchor="contact" data-tooltip="Contact">
      <div className="container align-self-center">
        <div className="row mb-5">
          <div className="col-12 col-xl-6">
            <h2 className="text-white mb-4 animated" data-animation="fadeInUp">Contact Us</h2>
            <p className="text-white animated" data-animation="fadeInUp" data-animation-delay="150">Want to say hello? Want to know more about us? Give us a call or drop us an email and we will get back to you as soon as we can.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-5 mb-5 mb-lg-0">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-6 mb-5 mb-md-0 mb-lg-5 animated" data-animation="fadeInUp" data-animation-delay="150">
                <div className="feature-block">
                  <div className="feature-icon mb-4 text-white">
                    <div>
                        <i className="ti-mobile" />
                      </div>
                  </div>
                  <p className="text-white">
                    (0091) 1111 1234567<br />
                    (084) 2222 1234567
</p>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-6 mb-5 mb-md-0 mb-lg-5 animated" data-animation="fadeInUp" data-animation-delay="150">
                <div className="feature-block">
                  <div className="feature-icon mb-4 text-white">
                    <div>
                        <i className="ti-location-pin" />
                      </div>
                  </div>
                  <p className="text-white">
                    69 Halsey St, New York, Ny 10002,<br />
                    United States
</p>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-6 animated" data-animation="fadeInUp" data-animation-delay="150">
                <div className="feature-block">
                  <div className="feature-icon mb-4 text-white">
                    <div>
                        <i className="ti-email" />
                      </div>
                  </div>
                  <p className="text-white">
                    support@domain.com<br />
                    hello@domain.com
</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 offset-lg-1 animated" data-animation="fadeInUp" data-animation-delay="150">
            <div className="contact-form">
              <form className="text-white mb-0" id="cf" name="cf" action="include/sendemail.php" method="post" autoComplete="off">
                <div className="form-row">

                  <div className="form-process" />

                  <div className="col-12 col-md-6">
                    <div className="form-group error-text-white">
                        <input type="text" id="cf-name" name="cf-name" placeholder="Enter your name" className="form-control fc-bordered fc-light required" />
                      </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group error-text-white">
                        <input type="email" id="cf-email" name="cf-email" placeholder="Enter your email address" className="form-control fc-bordered fc-light required" />
                      </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group error-text-white">
                        <input type="text" id="cf-subject" name="cf-subject" placeholder="Subject (Optional)" className="form-control fc-bordered fc-light" />
                      </div>
                  </div>

                  <div className="col-12 mb-4">
                    <div className="form-group error-text-white">
                        <textarea name="cf-message" id="cf-message" placeholder="Here goes your message" className="form-control fc-bordered fc-light required" rows="7" />
                      </div>
                  </div>

                  <div className="col-12 d-none">
                    <input type="text" id="cf-botcheck" name="cf-botcheck" value="" className="form-control fc-bordered fc-light" />
                  </div>

                  <div className="col-12">
                    <button className="btn btn-white" type="submit" id="cf-submit" name="cf-submit">Send Message</button>
                  </div>

                </div>
              </form>
              <div className="contact-form-result pt-1" />
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>

);

export default Content;
