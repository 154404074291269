import React from 'react';

const ScrollProgress = () => (
  <div className="scroll-progress">
    <div className="sp-left">
      <div className="sp-inner" />
      <div className="sp-inner progress" />
    </div>
    <div className="sp-right">
      <div className="sp-inner" />
      <div className="sp-inner progress" />
    </div>
  </div>
);

export default ScrollProgress;
