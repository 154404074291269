import React, { useEffect, useState } from 'react';
import Preloader from '../Components/Preloader';
import Header from '../Components/Header';
import BackToTop from '../Components/BackToTop';
import Overlay from '../Components/Overlay';
import ScrollProgress from '../Components/ScrollProgress';
import Footer from '../Components/Footer';
import Content from '../Components/Content';

import site from '../Utils/Site';

const Layout = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      site();
    }, 2000);
  }, []);
  return (
    <div className="site-container">

      <div id="top" />
      <Preloader loading={loading} />
      <Header />
      <BackToTop />
      <Overlay />
      <ScrollProgress />

      <Content />

      <Footer />
    </div>
  );
};

export default Layout;
