import React from 'react';

const Overlay = () => (
  <div className="global-overlay">
    <div className="overlay-inner bg-image-holder bg-cover">
      <img src="demo/images/image-4.jpg" alt="background" />
    </div>
    <div className="overlay-inner overlay-video">
      <div className="video-wrapper">
        <video autoPlay muted loop src="back.mp4" />
      </div>
    </div>
    <div className="overlay-inner bg-dark opacity-55" />
  </div>
);

export default Overlay;
